<template>
  <div class="user-module">
    <div class="user-info" v-if="subscriberId">
      <div class="vlt-header-notif">
        <button
          type="button"
          class="vlt-btn-ico"
          @click="toggleNotificationPopup"
          style="cursor: pointer"
        >
          <notification-highlight v-if="newMessage" :size="headerIconsHeight" />
          <img
            v-else
            src="@/assets/icons/notification-1.svg"
            class="notification-icon"
            alt="notif-btn-ico"
            loading="lazy"
          />
        </button>
      </div>
      <div class="vlt-header-prof">
        <div
          class="vlt-hprof"
          @click="toggleProfileMenu(true)"
          style="cursor: pointer"
        >
          <img
            v-if="picture"
            :src="picture"
            alt="vlt-prof"
            class="img-responsive custom-image"
          />
          <img
            v-else
            :src="defaultPicture"
            alt="vlt-prof"
            class="img-responsive default-image"
            loading="lazy"
          />
        </div>
      </div>
      <profile-menu
        v-show="showMenu"
        :size="`260px`"
        class="profile-menu"
        :closePopup="toggleProfileMenu"
        :showLoaderCB="showLoaderCB"
      />
      <notification
        v-show="showNotificationPopup"
        class="notification"
        :closePopup="toggleNotificationPopup"
        :showLoaderCB="showLoaderCB"
      />
    </div>
    <div class="user-info" v-else>
      <!--  @click="testFB -->
      <div
        @click="() => toggleLoginPopup(true)"
        class="login-signup-button vlt-btn"
      >
        <h5>LOGIN</h5>
      </div>
    </div>
    <transition name="popup-animate">
      <popup-login
        v-if="showPopuplogin"
        :previousActionData="loginPreviousActionData"
        :closePopupCB="() => toggleLoginPopup(false)"
      />
      <popup-signup
        v-if="showPopupsignup"
        :previousActionData="signupPreviousActionData"
        :closePopupCB="() => toggleSignupPopup(false)"
      />
      <popup-resetPassword
        v-if="showPopupResetPassword"
        :closePopupCB="() => toggleResetPasswordPopup(false)"
        :togglePopupMessage="(state, data) => togglePopupMessage(state, data)"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/main";
import GoogleAnalytics from "@/mixins/GoogleAnalytics";

export default {
  data() {
    return {
      showLoader: false,
      showPopuplogin: false,
      showPopupsignup: false,
      showPopupResetPassword: false,
      showPopupEditProfile: false,
      defaultPicture: require("@/assets/icons/user-default.svg"),
      showNotificationPopup: false,
      loginPreviousActionData: null,
      signupPreviousActionData: null,
      showMenu: false,
      showPopupMessage: false,
      popupMessageData: null,
      newMessage: false,
      headerIconsHeight: "28px",
    };
  },
  computed: {
    ...mapGetters(["appUser", "subscriberId", "picture", "fcmNotifications"]),
    promoCode(){
      return this.$route.path.includes('promo') && this.$route.query?.code ;
    }
  },
  watch: {
    fcmNotifications(val) {
      if (val === true) {
        this.newMessage = true;
      } else {
        this.newMessage = false;
      }
    },
  },
  methods: {
    ...mapMutations(["commitFcmNotifications"]),
    toggleLoginPopup(state) {
      this.showPopuplogin = state;
      if (!state) {
        this.loginPreviousActionData = null;
      }
    },
    toggleSignupPopup(state) {
      this.showPopupsignup = state;
    },
    showLoaderCB(state) {
      this.showLoader = state;
    },
    toggleNotificationPopup(state) {
      // console.log("state ", state);
      EventBus.$emit("dropdown-switch", { notification: state });
      this.showNotificationPopup = state;
      if (state) {
        this.commitFcmNotifications(false);
      } else {
        localStorage.removeItem("fcm_notifications_temp");
      }
    },
    toggleResetPasswordPopup(state) {
      if (state) {
        this.toggleLoginPopup(false);
      }
      this.showPopupResetPassword = state;
    },
    togglePopupMessage(state) {
      this.showPopupMessage = state;
    },
    toggleProfileMenu(state) {
      EventBus.$emit("dropdown-switch", { profile: state });
      this.showMenu = state;
    },
    closeAllPopups() {
      this.showSharedPopup = false;
      this.showAboutPopup = false;
      this.showNotificationPopup = false;
      this.showPopupEditProfile = false;
      this.showPopupsignup = false;
      this.showPopuplogin = false;
      this.showPopupResetPassword = false;
      this.showChangePasswordPopup = false;
      this.showContactSupportPopup = false;
    },
    fetchUserDataCB() {
      if (localStorage.getItem("appUser")) {
        let AppUser = JSON.parse(localStorage.getItem("appUser"));
        if (AppUser.picture) this.picture = AppUser.picture;
      }
    },
    promoCheck(){
      if( !this.promoCode || this.subscriberId ) return ;
      localStorage.setItem('promo_code_reference', this.promoCode );
      sessionStorage.setItem('promo_code_reference', this.promoCode ); 
      EventBus.$emit('SignPopup', {} );
    },
  },
  created() {
    if (this.fcmNotifications) {
      this.newMessage = true;
    } else {
      this.newMessage = false;
    }
    EventBus.$on("LoginPopup", (data) => {
      if (data) {
        this.loginPreviousActionData = data;
      } else {
        this.loginPreviousActionData = null;
      }
      this.closeAllPopups();
      this.toggleLoginPopup(true);
    });
    EventBus.$on("SignPopup", (data) => {
      if (data) {
        this.signupPreviousActionData = data;
      } else {
        this.signupPreviousActionData = null;
      }
      this.closeAllPopups();
      this.toggleSignupPopup(true);
    });
    EventBus.$on("ResetPasswordPopup", (state) => {
      this.closeAllPopups();
      this.toggleResetPasswordPopup(state);
    });
    EventBus.$on("close-notification", this.toggleNotificationPopup);
    EventBus.$on("close-profile", this.toggleProfileMenu);

    this.promoCheck()
  },
  components: {
    "popup-login": () =>
      import(
        /* webpackChunkName: "popupLogin" */ "@/components/Popups/login.vue"
      ),
    "popup-resetPassword": () =>
      import(
        /* webpackChunkName: "popupResetPassword" */ "@/components/Popups/resetPassword.vue"
      ),
    "popup-signup": () =>
      import(
        /* webpackChunkName: "popupSignup" */ "@/components/Popups/signup.vue"
      ),
    "profile-menu": () =>
      import(/* webpackChunkName: "profileMenu" */ "./profileMenu.vue"),
    notification: () =>
      import(/* webpackChunkName: "notification" */ "./notification.vue"),
    notificationHighlight: () =>
      import("@/components/svgComponents/notificationHighlight.vue"),
  },
  mixins: [GoogleAnalytics],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/components";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";
@import "~sass/modules/variables";

.img-responsive {
  float: left;
}
.user-module {
  height: 100%;
}
.user-info {
  // float: right;
  position: relative;
  display: flex;
  height: 100%;
  flex-flow: row nowrap;
  align-items: center;

  .profile-menu,
  .notification {
    position: absolute;
    top: $header-total-height + $dropdown-gap;
  }
  .profile-menu {
    right: 0;
  }
  .notification {
    right: $header-icons-height + 20px;
  }
}
.vlt-header-login {
  width: auto !important;
  display: flex;
  flex-direction: row;
  flex: 1;
}
.vlt-header-notif,
.vlt-header-prof {
  margin-left: 10px;
}
.vlt-header-prof {
  position: relative;
}
.vlt-header-notif {
  margin: 0 10px 0 0;
  button {
    position: relative;
    z-index: 91;
    // padding: 12px 0 0 0;
    img {
      width: 20px;
    }
  }
}
.vlt-btn {
  &:hover {
    letter-spacing: 0.2em;
  }
  &:active {
    transform: scale(0.98, 0.98);
  }
}
.vlt-btn-ico {
  background: transparent;
  border: none;
  display: block;
  &.visible {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $clr-dark-grad-2;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .notification-icon {
    width: $header-icons-height;
    display: block;
  }
}
.vlt-hprof {
  padding: 0;
  img {
    width: auto;
    height: $header-icons-height;
    border-radius: 50%;
    display: block;
    &.default-image {
      height: $header-icons-height - 1px;
    }
    &.custom-image {
      height: $header-right-height;
    }
  }
  div {
    text-align: center;
    float: left;
    color: white;
    background: black;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    vertical-align: middle;
    p {
      padding: 3px;
      font-size: 1.4rem;
    }
  }
}
.login-signup-button {
  padding: 0;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  width: 145px;
  height: $header-right-height -2px;
  cursor: pointer;
  text-align: center;
  h5 {
    font-size: 1.25rem;
    font-weight: $font-weight-semibold;
    line-height: 25px;
    text-transform: uppercase;
    vertical-align: middle;
    line-height: $header-right-height - 2px;
    height: $header-right-height - 2px;
  }
}
.vlt-header-login {
  margin-left: 20px;
  margin-top: 13px;
}
/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
@include breakpoint(max600) {
  // .vlt-header-notif,
  // .vlt-header-prof {
  //   // display: none;
  // }
  .login-signup-button {
    // width: 96px;
    width: 75px;
    // h5 {
    //   font-size: 12px;
    // }
  }
}

// @media screen and (min-width: 1900px) {
@include breakpoint(desktopLarge) {
  .login-signup-button {
    width: 120px;
    h5 {
      padding: 5px;
      // font-size: 20px;
    }
  }
}
@include breakpoint(max1024) {
  .login-signup-button {
    width: 115px;
  }
}
@include breakpoint(max768) {
  .login-signup-button {
    width: 80px;
    // h5 {
    //   font-size: 12px;
    // }
  }
}
</style>